import React, { useState, useEffect } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles'
import anime from 'animejs/lib/anime.min.js'
import { useSelector } from 'react-redux'
import { animationSelectors } from '@store/modules/Animation'

type StyleProps = {
  showLine: boolean
}

const useStyles = makeStyles(() => ({
  root: {
    width: '150%',
    maxWidth: '790px',
    maxHeight: '720px',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: -10,
    display: (props: StyleProps) => (props.showLine ? 'block' : 'none'),
    opacity: 0,
  },
  svg: {
    fill: 'none',
    stroke: '#ffb400',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '2px',
  },
}))

// /static/animation/*.svg -> convert at https://svg2jsx.com/ -> copy 'path d='
const svgs = {
  fly:
    'M-.2.3C23.4 63.9 59.7 122 106.5 171.1c25.8 27 73.5 101.1 123.2 158.8 66.9 77.5 181.1 33.1 235.7-17.8 20.5-23.5 54.4 32.6 57.7 19.1s9.8-30.7 24.3-67.3 21.3-88.6 30.3-70.2-194.7 88.9-200.6 73.5 41 1.9 54 2.9c50.8 3.8 112.8-30.1 84-25.7-29.2 4.4-52.7 15.4-57.1 34.3s-9.3 61.5-7.6 66c12.7 32.3 5.1-68.8 20.8-58.8 74.4 47.1 32.4 129.2 76.2 185.4 106.9 137.3 236.4 167.3 339.9 269.1',
  mail:
    'M0 0c23.6 63.6 59.9 121.7 106.7 170.8 25.8 27 99.3 71.5 141.4 173.3s153.6 100.4 133.7 48.1 10.8-173.9-17.3-161.2S487.4 344.5 494 344.1s108.1-67.6 78.9-97-147.5-27.9-153.9-11.3 78.3 20.2 112.6 14.8 52.8-20.8 59.2-6.8 20.2 140.6 4.5 163.9-195 31.3-183.2 0S542.2 536 604.9 582.6 784 638.3 887.5 740.1',
  heart:
    'M0 .5C26.6 62.4 64 119 110.5 167.8c29.3 30.7 62.1 58.1 89.3 90.8 44.6 53.6 45.6 113.9 144.3 136.3 38.9 8.8 76.3 12.3 111.3 9.9 53.9-3.7 101.7-21.8 139.4-56.9 11.6-10.8 22.9-23.1 26.7-38.4 3.8-15.4-3-34.4-18.1-39.1-21.7-6.8-65.3 25.4-50.8 39.1 14.6 13.7 37.2-73.5 10.7-88.3-26.5-14.9-67.2 29.7-75.4 83.2s24.8 97.9 42.8 130.8c8.9 16.4 18.6 32.8 32.5 45.3 13.9 12.5 31.6 20.5 48.5 28.7 102.2 50 191.1 121.4 275 198.8',
}

export type LineSvg = keyof typeof svgs

type Props = {
  showMobile?: boolean
}

const Line = ({ showMobile = false }: Props) => {
  const theme: Theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const showLine = showMobile || isDesktop
  const classes = useStyles({ showLine })
  const currentState = useSelector(animationSelectors.getFlowSvgCurrentState)
  const previousState = useSelector(animationSelectors.getFlowSvgPreviousState)

  const [svg, setSvg] = useState(svgs[currentState.svgName])
  const duration = 2500

  /* const morphPath = () => {
    const animation = anime({
      targets: '.morph-path',
      d: [
        {
          value: start,
        },
        {
          value: end,
        },
      ],
      direction: 'normal',
      easing: 'easeOutQuad',
      duration: 2000,
      autoplay: false,
      loop: false,
    })
    animation.play()
  }*/

  const changeSvg = changedSvg => {
    const fadeOutIn = anime({
      targets: '.morph-path',
      keyframes: [{ opacity: 1 }, { opacity: 0 }, { opacity: 0 }, { opacity: 0 }, { opacity: 1 }],
      direction: 'normal',
      easing: 'linear',
      duration: duration,
      autoplay: false,
      loop: false,
    })

    fadeOutIn.play()

    setTimeout(() => {
      setSvg(changedSvg)
    }, duration / 2.5)
  }

  const fadeIn = () => {
    const fadeIn = anime({
      targets: '.main-svg',
      keyframes: [{ opacity: 0 }, { opacity: 0 }, { opacity: 0 }, { opacity: 0 }, { opacity: 1 }],
      direction: 'normal',
      easing: 'linear',
      duration: duration,
      autoplay: false,
      loop: false,
    })

    fadeIn.play()
  }

  useEffect(() => {
    fadeIn()
  }, [])

  useEffect(() => {
    if (previousState.svgName !== currentState.svgName) {
      changeSvg(svgs[currentState.svgName])
    }
  }, [previousState, currentState])

  return (
    <div className={`${classes.root} main-svg`}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        data-name='Vrstva 1'
        viewBox='0 0 887 740'
        x='0'
        y='0'
        enableBackground='new 0 0 887 740'
      >
        <path d={svg} className={classes.svg + ' morph-path'} data-name='Path 42-3'></path>
      </svg>
    </div>
  )
}

export default Line
